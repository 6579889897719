body {
  background: #91A3B0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Row {
  display: flex;
}

.Column {
  /* -webkit-flex: 1; */
  -ms-flex: 1;
  flex: 1;
  padding: 10px;
}

